var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"run-settings"}},[_c('NewGarage',{attrs:{"isOpen":_vm.showGarageModal},on:{"update:isOpen":function($event){_vm.showGarageModal=$event},"update:is-open":function($event){_vm.showGarageModal=$event},"save":function($event){_vm.newGarageKey++}}}),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"titles"},[_vm._v(_vm._s(_vm.$t('run.settings.pullOut')))]),_c('div',{staticClass:"titles"},[_vm._v(_vm._s(_vm.$t('run.settings.pullIn')))])]),_c('div',{staticClass:"card-form"},[_c('form',{ref:"form"},[_c('b-field',{attrs:{"grouped":""}},[_c('Autocomplete',{key:_vm.newGarageKey + 'pullOut',staticClass:"w-50",attrs:{"label":_vm.$t('vehicle.garage'),"placeholder":_vm.$t('global.placeholder.select', [_vm.$tc('')]),"api":{
            url: 'vehicle_garages',
            params: [{ id: 'is_active', value: 1 }],
            full: true
          },"field":"full_address","value":_vm.oRun[['pull_out_garage.full_address']],"required":""},on:{"select":function (data) {
              _vm.oRun.pull_out_vehicle_garage_id = data.id;
              _vm.pull_out_address = data.full_address;
            },"add":function () {
              _vm.showGarageModal = true;
            }}}),_c('Autocomplete',{key:_vm.newGarageKey + 'pullIn',staticClass:"w-50-no-padding",attrs:{"label":_vm.$t('vehicle.garage'),"placeholder":_vm.$t('global.placeholder.select', [_vm.$tc('')]),"api":{
            url: 'vehicle_garages',
            params: [{ id: 'is_active', value: 1 }],
            full: true
          },"field":"full_address","value":_vm.oRun[['pull_in_garage.full_address']],"required":""},on:{"select":function (data) {
              _vm.oRun.pull_in_vehicle_garage_id = data.id;
              _vm.pull_in_address = data.full_address;
            },"add":function () {
              _vm.showGarageModal = true;
            }}})],1),_c('div',{staticClass:"titles"},[_vm._v(_vm._s(_vm.$t('run.settings.subtitle')))]),_c('p',[_vm._v(_vm._s(_vm.$t('run.settings.optional')))]),_c('div',{staticClass:"funding-sources"},[_c('div',{staticClass:"fs-content"},[_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.$t('run.settings.fundingSrc')))]),(_vm.loading.get)?_c('div',{staticClass:"flex f-ai"},[_c('b-skeleton',{attrs:{"height":"20","width":"90%"}}),_c('b-skeleton',{attrs:{"height":"20","width":"90%"}}),_c('b-skeleton',{attrs:{"height":"20","width":"90%"}}),_c('b-skeleton',{attrs:{"height":"20","width":"90%"}})],1):_c('b-field',{attrs:{"grouped":""}},_vm._l((4),function(column){return _c('div',{key:column + 'column',staticStyle:{"width":"25%"}},_vm._l((_vm.getFundingSourcesColumnArray(column)),function(funding,i){return _c('b-checkbox',{key:i + funding.name,attrs:{"native-value":funding.id,"disabled":""},model:{value:(_vm.fundingSrcSelected),callback:function ($$v) {_vm.fundingSrcSelected=$$v},expression:"fundingSrcSelected"}},[_vm._v(" "+_vm._s(funding.name)+" ")])}),1)}),0)],1)])],1)]),_c('save-button',{attrs:{"loading":_vm.loading.save},on:{"click":_vm.onSave}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }