<template>
  <div id="run-daily-times">
    <form ref="form" class="flex f-jc f-full">
      <div class="from-to-time-container">
        <FromToTime
          v-for="(weekDay, i) in weekDays"
          :key="i"
          :day="weekDay.name"
          :valueFrom="getValues(weekDay.value, 'from_time')"
          :valueTo="getValues(weekDay.value, 'to_time')"
          @fromTime="
            (time) => {
              setTime(weekDay.value, time, 'from_time');
            }
          "
          @toTime="
            (time) => {
              setTime(weekDay.value, time, 'to_time');
            }
          "
        />
      </div>
      <!-- <div class="daily-run-container">
        <div class="daily-run">
          <div class="sub-title" style="margin-bottom: 10px">
            {{ $t('run.dailyTimes.createDailyRun') }}
          </div>
          <Field>
            <DatePicker iconRight="calendar-blank" :label="$t('global.selectDate')" class="w-30" />
          </Field>
          <div class="flex f-jce">
            <b-button type="is-primary">{{ $t('button.create') }}</b-button>
          </div>
        </div>
      </div> -->
    </form>
    <save-button @click="onSave" :loading="loading.save" />
  </div>
</template>

<script>
import { toast } from '@/utils/dialog';
/* import Field from '@/components/Field.vue';
import DatePicker from '@/components/DatePicker.vue'; */
import FromToTime from '@/components/Runs/FromToTime.vue';
import saveButton from './saveButton.vue';

export default {
  components: {
    /* Field,
    DatePicker, */
    FromToTime,
    saveButton
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
    this.dailyTimes();
  },
  data() {
    const oRun = { ...this.run };
    return {
      loading: { save: false, get: false },
      times: [],
      oRun
    };
  },
  watch: {
    run(run) {
      this.oRun = run;
      this.dailyTimes();
    }
  },
  computed: {
    breadcumbs() {
      return ['menu.dat', this.run.id ? 'run.edit' : 'run.new', 'global.dailyTimes'];
    },
    weekDays() {
      return [
        { name: this.$t('global.weekDays.sunday'), value: 6 },
        { name: this.$t('global.weekDays.monday'), value: 0 },
        { name: this.$t('global.weekDays.tuesday'), value: 1 },
        { name: this.$t('global.weekDays.wednesday'), value: 2 },
        { name: this.$t('global.weekDays.thursday'), value: 3 },
        { name: this.$t('global.weekDays.friday'), value: 4 },
        { name: this.$t('global.weekDays.saturday'), value: 5 }
      ];
    }
  },
  methods: {
    setTime(day, time, prop) {
      let dayExists = this.times.filter((d) => day == d.day);
      if (dayExists.length > 0) {
        dayExists[0][prop] = time;
      } else {
        this.times.push({ day: day, [prop]: time });
      }
    },
    getValues(day, prop) {
      let value = this.times.filter((time) => {
        return time.day == day;
      });
      if (value.length > 0) {
        return value[0][prop];
      } else {
        return null;
      }
    },
    dailyTimes() {
      let arr = [];
      if (this.oRun.daily_times) {
        arr = JSON.parse(this.oRun.daily_times);
        this.times = arr;
      } else {
        arr = [];
      }
      return arr;
    },
    async onSave() {
      if (this.validate()) {
        this.loading.save = true;
        try {
          const pData = {
            daily_times: JSON.stringify(this.times)
          };
          const { data } = await this.Api.put(`/driver_runs/${this.oRun.id}`, pData);
          this.oRun.id = data.id;
          this.oRun.daily_times = data.daily_times;
          toast('success', this.$t('messages.saved'), 5000);
          this.$emit('change', this.oRun);
        } catch (error) {
          console.error(error);
        }
        this.loading.save = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  props: {
    run: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
#run-daily-times
  padding-top: 30px
  .titles
    font-size: $f-md
    font-weight: bold
    margin-bottom: 5px
  .sub-title
    font-size: $f-lg
    font-weight: bold
    margin-right: 15px
  .from-to-time-container
    width: 70%
    #from-to-time-component
      margin: 15px auto
  .daily-run-container
    min-width: 30%
    .daily-run
      max-width: 350px
      height: fit-content
      padding: 20px
      transition: $transition-dark-mode
      border-radius: $br-md
      border: 1px solid #dbdbdb
  ::v-deep .field .label
      font-size: $f-md
</style>
