<template>
  <div id="from-to-time-component">
    <div class="flex f-ai f-full f-jce">
      <div class="sub-title">{{ day }}</div>

      <b-field grouped class="flex f-full">
        <TimePicker
          :label="$t('run.dailyTimes.from')"
          :placeholder="$t('global.placeholder.select', [$tc('')])"
          class="w-50"
          style="margin-right: 10px"
          v-model="fromTime"
        />
        <TimePicker
          :label="$t('run.dailyTimes.to')"
          :placeholder="$t('global.placeholder.select', [$tc('')])"
          class="w-50"
          v-model="toTime"
        />
      </b-field>
      <b-button type="is-danger" outlined @click="clear">{{ $t('button.clear') }}</b-button>
    </div>
  </div>
</template>

<script>
import TimePicker from '@/components/TimePicker.vue';

export default {
  components: {
    TimePicker
  },
  data() {
    return {
      fromTime: this.valueFrom,
      toTime: this.valueTo
    };
  },
  watch: {
    fromTime(value) {
      this.$emit('fromTime', value);
    },
    toTime(value) {
      this.$emit('toTime', value);
    },
    valueFrom(value) {
      this.fromTime = value;
    },
    valueTo(value) {
      this.toTime = value;
    }
  },
  methods: {
    clear() {
      this.fromTime = null;
      this.toTime = null;
    }
  },
  props: {
    day: {
      type: String,
      required: true
    },
    valueFrom: { default: null },
    valueTo: { default: null }
  }
};
</script>

<style lang="sass" scoped>
#from-to-time-component
  width: 80%
  .titles
    font-size: $f-md
    font-weight: bold
    width: 50%
    text-transform: none
    margin-bottom: 5px
  .sub-title
    font-size: $f-lg
    font-weight: bold
    margin-right: 15px
    text-align: right
    width: 150px
    align-self: flex-end
    height: 40px
    align-items: center
    justify-content: flex-end
    display: flex
  .button
    height: 40px
    padding: 7px 14px
    align-self: flex-end
  .field:not(:last-child)
    margin-bottom: 0
  ::v-deep
    .timepicker .input
      padding-left: 0.75rem
    .icon.is-left
      right: 0
      left: auto
  .w-10
    width: calc(10% - 0.75rem)
  .w-15
    width: calc(15% - 0.75rem)
  .w-20
    width: calc(20% - 0.75rem)
  .w-30
    width: calc(30% - 0.75rem)
  .w-40
    width: calc(40% - 0.75rem)
  .w-50
    width: calc(50% - 0.75rem)
</style>
