<template>
  <div id="newGarage">
    <Modal
      :isOpen.sync="isModalOpen"
      :header="$t('modal.add', [$tc('vehicle.garage')])"
      size="is-medium"
      :loading="loading"
      @cancel="() => {}"
      @save="onSubmit"
    >
      <form ref="form">
        <Field :label="$t('modal.name')" v-model="address.name" :maxlength="60" required />
        <AddressPicker ref="addressPicker" v-model="address" dropdownPosition="top" />
      </form>
    </Modal>
  </div>
</template>

<script>
import Field from '@/components/Field.vue';
import Modal from '@/components/Modal.vue';
import AddressPicker from '@/components/Address/AddressPicker.vue';
import { toast } from '@/utils/dialog';

export default {
  components: {
    Field,
    Modal,
    AddressPicker
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
      if (type == 'map/response') {
        console.warn(map?.response?.data?.latLng);
        if (map?.response?.action == 'position') this.setPosition(map?.response?.data?.latLng);
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      address: {},
      isModalOpen: false,
      loading: { save: false, get: false },
      unsubscribe: null
    };
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  methods: {
    onPickPosition(address) {
      this.showMap(address, true);
    },
    onShowPosition(address) {
      this.showMap(address, false);
    },
    prepareForMaps({ address, state, city, latitude, longitude, zipcode }) {
      const comp = [];
      if (address) comp.push(address);
      if (city) comp.push(city);
      if (state) comp.push(state);
      if (zipcode) comp.push(`zc ${zipcode}`);
      return {
        address: comp.join(', '),
        latLng: { lat: latitude, lng: longitude }
      };
    },
    restoreMap() {
      this.$store.commit('map/update', { action: 'restore' });
    },
    setPosition({ lat, lng } = {}) {
      this.address.latitude = lat || null;
      this.address.longitude = lng || null;
    },
    showMap(oAdress, pick) {
      const data = this.prepareForMaps(oAdress);
      this.$store.dispatch('map/updateMapWindow', {
        action: pick ? 'getPosition' : 'showPosition',
        data
      });
    },
    updateMapBreadcumbs(clear = false) {
      this.$store.commit('map/breadcumbs', clear ? null : this.breadcumbs);
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading.save = true;
        const { address } = this;
        try {
          await this.Api.post('/vehicle_garages', {
            name: address.name,
            address: address.address,
            address_1: address.address_1,
            state: address.state,
            city: address.city,
            zipcode: address.zipcode,
            latitude: address.latitude || '',
            longitude: address.longitude || '',
            formatted_address: address.formatted_address || ''
          });
          this.address = {};
          toast('success', this.$t('messages.saved'), 5000);
          this.isModalOpen = false;
          this.$emit('save');
        } catch (error) {
          console.error(error);
        }
        this.loading.save = false;
      }
    },
    validate() {
      const addressValidator = this.$refs.addressPicker.validate();
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator && addressValidator;
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
#newGarage
  ::v-deep
    .modal-footer button
      &:last-child
        margin: 5px 0px 5px 0px !important
    .ap-address
      width: 100%
      margin-right: 0
    .ap-state,
    .ap-city,
    .ap-address1
      width: calc(50% - .38rem)
    .ap-city
      margin-right: 0px
    .ap-zipcode
      width: calc(45% - 1.3rem)
    .ap-position
      margin-bottom: 0
</style>
