<template>
  <div id="run-settings">
    <NewGarage :isOpen.sync="showGarageModal" @save="newGarageKey++" />
    <div class="flex">
      <div class="titles">{{ $t('run.settings.pullOut') }}</div>
      <div class="titles">{{ $t('run.settings.pullIn') }}</div>
    </div>

    <div class="card-form">
      <form ref="form">
        <b-field grouped>
          <Autocomplete
            :key="newGarageKey + 'pullOut'"
            :label="$t('vehicle.garage')"
            :placeholder="$t('global.placeholder.select', [$tc('')])"
            :api="{
              url: 'vehicle_garages',
              params: [{ id: 'is_active', value: 1 }],
              full: true
            }"
            class="w-50"
            @select="
              (data) => {
                oRun.pull_out_vehicle_garage_id = data.id;
                pull_out_address = data.full_address;
              }
            "
            field="full_address"
            :value="oRun[['pull_out_garage.full_address']]"
            required
            @add="
              () => {
                showGarageModal = true;
              }
            "
          />
          <Autocomplete
            :key="newGarageKey + 'pullIn'"
            :label="$t('vehicle.garage')"
            :placeholder="$t('global.placeholder.select', [$tc('')])"
            :api="{
              url: 'vehicle_garages',
              params: [{ id: 'is_active', value: 1 }],
              full: true
            }"
            class="w-50-no-padding"
            @select="
              (data) => {
                oRun.pull_in_vehicle_garage_id = data.id;
                pull_in_address = data.full_address;
              }
            "
            field="full_address"
            :value="oRun[['pull_in_garage.full_address']]"
            required
            @add="
              () => {
                showGarageModal = true;
              }
            "
          />
        </b-field>
        <div class="titles">{{ $t('run.settings.subtitle') }}</div>
        <p>{{ $t('run.settings.optional') }}</p>

        <div class="funding-sources">
          <div class="fs-content">
            <div class="sub-title">{{ $t('run.settings.fundingSrc') }}</div>
            <div v-if="loading.get" class="flex f-ai">
              <b-skeleton height="20" width="90%" />
              <b-skeleton height="20" width="90%" />
              <b-skeleton height="20" width="90%" />
              <b-skeleton height="20" width="90%" />
            </div>
            <b-field v-else grouped>
              <div v-for="column in 4" :key="column + 'column'" style="width: 25%">
                <b-checkbox
                  v-for="(funding, i) in getFundingSourcesColumnArray(column)"
                  :key="i + funding.name"
                  :native-value="funding.id"
                  v-model="fundingSrcSelected"
                  disabled
                >
                  {{ funding.name }}
                </b-checkbox>
              </div>
            </b-field>
          </div>
        </div>
      </form>
    </div>
    <save-button @click="onSave" :loading="loading.save" />
  </div>
</template>

<script>
import { toast } from '@/utils/dialog';
import Autocomplete from '@/components/Autocomplete.vue';
import NewGarage from '@/components/Vehicles/NewGarage.vue';
import saveButton from './saveButton.vue';

export default {
  components: {
    Autocomplete,
    NewGarage,
    saveButton
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
    this.getDriverRun();
    this.getFundingSources();
    this.getFundingSrcSelected();
  },
  computed: {
    breadcumbs() {
      return ['menu.dat', this.run.id ? 'run.edit' : 'run.new', 'global.settings'];
    }
  },
  data() {
    const oRun = { ...this.run };
    return {
      loading: { save: false, get: false },
      showGarageModal: false,
      newGarageKey: 1,
      fundingSources: [],
      fundingSrcSelected: [],
      pull_in_address: null,
      pull_out_address: null,
      oRun
    };
  },
  watch: {
    run(run) {
      this.oRun = run;
      this.getFundingSrcSelected();
    }
  },
  methods: {
    async getDriverRun() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get(`driver_runs/${this.oRun.id}`);
        this.oRun = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    async getFundingSources() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get('funding_sources');
        this.fundingSources = [];
        data.map((fundingSrc) => {
          this.fundingSources.push({ id: fundingSrc.id, name: fundingSrc.name });
        });
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    getFundingSrcSelected() {
      this.fundingSrcSelected = [];
      let arr = [];
      if (this.oRun.exclusive_funding_source) {
        arr = JSON.parse(this.oRun.exclusive_funding_source);
        arr.map((val) => this.fundingSrcSelected.push(val));
      } else {
        arr = [];
      }
      return arr;
    },
    getFundingSourcesColumnArray(column) {
      let length = Math.ceil(this.fundingSources.length / 4);
      let array = [];
      switch (column) {
        case 1:
          array = this.fundingSources.slice(0, length);
          break;
        case 2:
          array = this.fundingSources.slice(length, length * 2);
          break;
        case 3:
          array = this.fundingSources.slice(length * 2, length * 3);
          break;
        case 4:
          array = this.fundingSources.slice(length * 3, this.fundingSources.length);
          break;
      }
      return array;
    },
    prepareData() {
      const { oRun: R } = this;
      return {
        pull_out_vehicle_garage_id: R.pull_out_vehicle_garage_id,
        pull_in_vehicle_garage_id: R.pull_in_vehicle_garage_id,
        exclusive_funding_source: JSON.stringify(this.fundingSrcSelected)
      };
    },
    async onSave() {
      if (this.validate()) {
        this.loading.save = true;
        const pData = this.prepareData();
        try {
          const { data } = await this.Api.put(`/driver_runs/${this.oRun.id}`, pData);
          this.oRun.id = data.id;
          this.getDriverRun();
          this.getFundingSrcSelected();
          toast('success', this.$t('messages.saved'), 5000);
          this.$emit('change', this.oRun);
        } catch (error) {
          console.error(error);
        }
        this.loading.save = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  props: {
    run: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
#run-settings
  .titles
    font-size: $f-xl
    font-weight: bold
    margin-bottom: 10px
    width: 50%
    margin-top: 25px
  .sub-title
    font-size: $f-lg
    font-weight: bold
    margin-top: 10px
  .card-form
    display: flex
    margin-bottom: 10px
    form
      width: 100%
      .funding-sources
        transition: $transition-dark-mode
        justify-content: center
        margin: 25px 0px 25px 0px
        border-radius: $br-md
        border: 1px solid #dbdbdb
        padding: 20px
        .fs-content
          ::v-deep
            .field .label
              color: $gray
            .b-checkbox.checkbox:not(.button)
              margin: 0.5rem 1rem 0.5rem 0
            .field:not(:last-child)
              margin-bottom: 0
            .b-skeleton
              margin: 10px 0px
            .field.is-grouped
              display: flex
              justify-content: space-between
.w-10
  width: calc(10% - 0.75rem)
.w-15
  width: calc(15% - 0.75rem)
.w-20
  width: calc(20% - 0.75rem)
.w-30
  width: calc(30% - 0.75rem)
.w-40
  width: calc(40% - 0.75rem)
.w-50
  width: calc(50% - 0.75rem)
.w-50-no-padding
  width: 50%
</style>
